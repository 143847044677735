<template>
  <b-card>
    <b-form @submit.prevent>

       <b-row>
          <!-- studio adı-->
          <b-col cols="12">
              <b-form-group
                :label="$t('Facility Name')"
                label-for="company_name"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ItalicIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="company_name"
                    name="company_name"
                    type="text"
                    :placeholder="$t('Facility Name')"
                    v-model="studio_data.company_name"
                      @blur="$v.studio_data.company_name.$touch()"
                  />

                </b-input-group>
                <small v-if="isSubmit && !$v.studio_data.company_name.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
              </b-form-group>

          </b-col>

          <!-- studio resmi adı-->
          <b-col cols="12">

              <b-form-group
                :label="$t('Facility Official Name')"
                label-for="company_official_title"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="CheckCircleIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="company_official_title"
                    name="company_official_title"
                    type="text"
                    :placeholder="$t('Facility Name')"
                    v-model="studio_data.company_official_title"
                     @blur="$v.studio_data.company_official_title.$touch()"
                  />
                </b-input-group>
                <small v-if="isSubmit && !$v.studio_data.company_official_title.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
              </b-form-group>

          </b-col>
          <!-- Telefon Numarası-->
          <b-col cols="12">
            <b-form-group
              :label="$t('Phone Number')"
              label-for="phone-number"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="PhoneIcon" />
                </b-input-group-prepend>
                <input
                  class="form-control"
                  id="phone-number"
                  name="phone-number"
                  :placeholder="$t('Phone Number')"
                  type="number"
                  maxlength="20"
                  v-model="studio_data.phone_number"
                  @blur="$v.studio_data.phone_number.$touch()"
                />
              </b-input-group>
                  <small v-if="isSubmit && !$v.studio_data.phone_number.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                  <small v-if="isSubmit && !$v.studio_data.phone_number.numeric" class="form-text text-danger" >{{$t("The field may only contain numeric characters")}}</small>
                  <small v-if="isSubmit && !$v.studio_data.phone_number.maxLength" class="form-text text-danger" >{{$t("Can contain a maximum of 20 characters")}}</small>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group :label="$t('City')" label-for="city">
              <v-select
                style="width: 100%; z-index: 450; display: inline-block"
                :reduce="x => x.city_id"
                v-model="selectedCity"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="cities"
                value="city_id"
                label="city_name"
                name="city"
                id="city"
                @blur="$v.studio_data.city_id.$touch()"
                ></v-select>

                <small v-if="isSubmit && !$v.studio_data.city_id.required" class="form-text text-danger" >{{$t("This field is required")}}</small>

            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group :label="$t('Town')" label-for="town">
              <v-select
                style="width: 100%; z-index: 400; display: inline-block"
                :reduce="x => x.town_id"
                v-model="selectedTown"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="towns"
                value="town_id"
                label="town_name"
                name="town"
                @blur="$v.studio_data.town_id.$touch()"
              >
              </v-select>
                 <small v-if="isSubmit && !$v.studio_data.town_id.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
            </b-form-group>
          </b-col>

          <!-- <b-col cols="12">
                <b-form-group label-for="scheduler" :label="$t('Company Type')">
                    <b-form-radio
                    v-model="studio_data.companyType"
                    name="companyType"
                    value="true"
                    class="d-inline custom-radio"
                    >
                    {{$t('Anonymous/Ltd')}}
                    </b-form-radio>
                    <b-form-radio
                    v-model="studio_data.companyType"
                    name="companyType"
                    value="false"
                    class="d-inline mx-2 custom-radio"
                    >
                    {{$t('Sole Proprietorship')}}
                    </b-form-radio>
                  <small v-if="isSubmit && !$v.studio_data.companyType.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                </b-form-group>
          </b-col> -->

          <!-- <b-col cols="12" v-if="studio_data.companyType == 'true'">
            <b-form-group :label="$t('Tax Code')" label-for="vi-first-name">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="AlignCenterIcon" />
                </b-input-group-prepend>
                <input
                  class="form-control"
                  id="vi-first-name"
                  :placeholder="$t('Tax Code')"
                  type="number"
                  maxlength="20"
                  v-model="studio_data.tax_code"
                  @blur="$v.studio_data.tax_code.$touch()"
                />
              </b-input-group>
                 <small v-if="isSubmit && !$v.studio_data.tax_code.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                 <small v-if="isSubmit && !$v.studio_data.tax_code.numeric" class="form-text text-danger" >{{$t("The field may only contain numeric characters")}}</small>
                 <small v-if="isSubmit && !$v.studio_data.tax_code.maxLength" class="form-text text-danger" >{{$t("Can contain a maximum of 20 characters")}}</small>
            </b-form-group>
          </b-col> -->
          <!-- <b-col cols="12" v-if="studio_data.companyType == 'false'">
            <b-form-group :label="$t('Identification Number')" label-for="vi-first-name">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="AlignCenterIcon" />
                </b-input-group-prepend>
                <input
                  class="form-control"
                  id="vi-first-name"
                  :placeholder="$t('Identification Number')"
                  type="number"
                  maxlength="20"
                  v-model="studio_data.tax_code"
                  @blur="$v.studio_data.tax_code.$touch()"
                />
              </b-input-group>
                 <small v-if="isSubmit && !$v.studio_data.tax_code.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                 <small v-if="isSubmit && !$v.studio_data.tax_code.numeric" class="form-text text-danger" >{{$t("The field may only contain numeric characters")}}</small>
                 <small v-if="isSubmit && !$v.studio_data.tax_code.maxLength" class="form-text text-danger" >{{$t("Can contain a maximum of 20 characters")}}</small>
            </b-form-group>
          </b-col> -->

          <!-- <b-col cols="12" v-if="studio_data.companyType == 'true'">

            <b-form-group
              :label="$t('Tax Administration')"
              label-for="vi-task-administration"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="BookOpenIcon" />
                </b-input-group-prepend>
                <b-form-input
                name="vi-task-administration"
                  id="vi-task-administration"
                  :placeholder="$t('Tax Administration')"
                  v-model="studio_data.tax_administration"
                  @blur="$v.studio_data.tax_administration.$touch()"
                />
              </b-input-group>
                <small v-if="isSubmit && !$v.studio_data.tax_administration.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
            </b-form-group>
          </b-col>
 -->


<!--
          <b-col cols="12">
            <b-form-group :label="$t('IBAN')" label-for="vi-first-name">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="AlignCenterIcon" />
                </b-input-group-prepend>
                <input
                  class="form-control"
                  id="vi-first-name"
                  :placeholder="$t('IBAN')"
                  maxlength="34"
                  v-model="studio_data.iban"
                  @blur="$v.studio_data.iban.$touch()"
                />
              </b-input-group>
                 <small v-if="isSubmit && !$v.studio_data.iban.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                 <small v-if="isSubmit && !$v.studio_data.iban.maxLength" class="form-text text-danger" >{{$t("Can contain a maximum of 20 characters")}}</small>
            </b-form-group>
          </b-col> -->


          <b-col cols="12">

            <b-form-group
              :label="$t('Email')"
              label-for="email"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="MailIcon" />
                </b-input-group-prepend>
                <b-form-input
                  name="email"
                  id="email"
                  :placeholder="$t('Email')"
                  v-model="studio_data.email"
                  @blur="$v.studio_data.email.$touch()"
                />
              </b-input-group>
                <small v-if="isSubmit && !$v.studio_data.email.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                <small v-if="isSubmit && !$v.studio_data.email.email" class="form-text text-danger" >{{$t("The email field must be a valid email")}}</small>
            </b-form-group>
          </b-col>

          <b-col cols="12">

            <b-form-group :label="$t('Address')" label-for="address">
              <b-input-group class="input-group-merge">
               <b-input-group-prepend is-text>
                  <feather-icon icon="MapIcon" />
                </b-input-group-prepend>
               <b-form-textarea
                  v-model="studio_data.adress"
                  id="address"
                  name="address"
                  :placeholder="$t('Address')"
                  rows="1"
                  @blur="$v.studio_data.adress.$touch()"
                />
              </b-input-group>
                    <small v-if="isSubmit && !$v.studio_data.adress.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
            </b-form-group>

          </b-col>


          <b-col cols="12" class="right">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              type="reset"
              variant="outline-primary"
              class="mr-1"
              :to="{ name: 'studio_list' }"
            >
              {{ $t("Cancel") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click="create"
            >
              {{ $t("Save") }}
            </b-button>
          </b-col>
        </b-row>
    </b-form>

  </b-card>
</template>

<script>
import {
  BFormTextarea,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BCardText,
  BFormRadio
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {required, numeric, maxLength, email} from "vuelidate/lib/validators";

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    Cleave,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BCardText,
    vSelect,
    mapGetters,
    mapActions,
    BFormTextarea,
    BFormRadio
  },
  props: {

  },

  data() {
    return {
      selectedCity:{
        city_id:"00000000-0000-0000-0000-000000000000",
        city_name: this.$t('Please choose')
      },
      selectedTown:
      {
        town_id:"00000000-0000-0000-0000-000000000000",
        town_name: this.$t('Please choose')
      },
      towns: [],
      cities: [],
      studio_data: {
        company_branch_id:null,
        company_name:null,
        company_official_title:null,
        phone_number:null,
        city_id :null,
        town_id :null,
       // tax_administration:null,
       // tax_code:null,
        adress:null,
        //iban: null,
        email:null,
        companyType: null
        },
        dataloaded:false,
        selectedIndex: 0,
        isSubmit: false,
    };
  },
  validations:
    {
        studio_data:{

                company_name:{
                  required:required
                },
                company_official_title:{
                  required:required
                },
                 phone_number:{
                    required:required,
                    numeric:numeric,
                    maxLength: maxLength(20)
                 },
                 city_id :{
                     required:required
                 },
                 town_id :{
                     required:required
                 },
                 adress:{
                   required:required
                 },
                 email: {
                   email,
                   required
                 },
                // companyType: {
                //   required
                // }
        },
    },


  methods: {
    ...mapActions("mockUser", ["fetchUser"]),


    create() {
      
      this.isSubmit = true;
       if (this.$v.$invalid)
              return;
        this.$database.DistributorService.company_create(
        this.studio_data.company_name ,
        this.studio_data.company_official_title,
        this.studio_data.company_official_title,
        this.studio_data.phone_number,
        this.studio_data.email,
        this.studio_data.city_id,
        this.studio_data.town_id,
        this.studio_data.adress,
      ).then((res) => {
        if (!res.IsSuccess) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t(res.Result),
              icon: "XIcon",
              variant: "danger",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t(res.Result),
              icon: "CheckIcon",
              variant: "success",
            },
          })
           this.$router.push("/studioList"); //sayfa yönlendirme
        }
      });
    },
    getCities() {
      this.$database.DistributorService.studio_get_cities().then((res) => {
        if (!res.is_success)
              return;
            this.cities = res.result;
             // this.studio_data.city_id=res.result[0].city_id
               this.dataloaded=true;
            //  this.getTowns();
      });
    },
  },
  watch: {
    //2.kontrol mekanizmam - asenkron inputları izleyerek şu an 20 (max) karakter ile sınırlandırıyorum
    'studio_data.phone_number'(val){
      if(this.studio_data.phone_number.length > 20){
        this.studio_data.phone_number = val.slice(0,20)
      }
    },
    'studio_data.tax_code'(val){
      if(this.studio_data.tax_code.length > 20){
        this.studio_data.tax_code = val.slice(0,20)
      }
    },
    'studio_data.companyType'(val){
      if(val == 'false'){
        this.studio_data.tax_administration = 'Şahıs Şirketi'
      }else {
        this.studio_data.tax_administration = ''
      }
    },


    selectedCity(val)
     {
      if(this.dataloaded===true)
      {
        this.selectedTown = {};
        this.studio_data.city_id = val;
        this.towns = [];
        this.$database.DistributorService.studio_get_towns_by_city_id(
          val

        ).then((res) => {
          if (!res.is_success)
              return;
              this.towns = res.result;
              this.selectedTown.town_id="00000000-0000-0000-0000-000000000000";
              this.selectedTown.town_name="Lütfen Seçim Yapınız";
        });
      }
    },
    selectedTown(val) {
      this.studio_data.town_id = val;
    },
  },
  created()
  {
    this.getCities();
  },
};
</script>

<style lang="scss" src="@/styles/scss/studioCreate.scss" scoped></style>
